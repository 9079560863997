import "./main.css"
import { onReady } from "./js/utils/dom"
import { initToggleNav } from "./js/components/nav"
import { initArticleList } from "./js/components/articles"
import { initAnchorAnimations } from "./js/components/anchor-animations"
import { initHeroDown } from "./js/components/hero-down"
import { initTop10 } from "./js/components/top10"

onReady(initToggleNav)
onReady(initHeroDown)
onReady(initArticleList)
onReady(initAnchorAnimations)
onReady(initTop10)
