import { qsa } from "../utils/dom"

export const initTop10 = () => {
  const snippets = qsa(".ce--top10")
  snippets.forEach((snippet) => {
    top10Snippet(snippet)
  })
}

const top10Snippet = (root) => {
  const buttons = qsa("button", root)
  const containers = qsa(".graph-container, .graph", root)

  const activate = (target) => {
    console.log(target)

    const toggle = target.dataset.toggle
    const toggleClass = target.dataset.class

    if (!target.classList.contains("active")) target.classList.add("active")
    buttons
      .filter(
        (button) =>
          button.dataset.toggle !== toggle &&
          button.dataset.class === toggleClass,
      )
      .forEach((button) => {
        if (button.classList.contains("active"))
          button.classList.remove("active")
      })

    const targetContainer = containers.find(
      (container) => container.dataset.toggle === toggle,
    )
    if (!targetContainer.classList.contains("active"))
      targetContainer.classList.add("active")
    containers
      .filter(
        (container) =>
          container.dataset.toggle !== toggle &&
          container.dataset.class === toggleClass,
      )
      .forEach((container) => {
        if (container.classList.contains("active"))
          container.classList.remove("active")
      })
  }

  buttons.forEach((button) => {
    button.onclick = () => {
      activate(button)
    }
  })
}
