import { qs, qsa } from "../utils/dom"

const body = document.body

export function initToggleNav() {
  const menuToggles = qsa("[data-menu-toggle]")
  const mainNav = qs("#main-nav")
  const mainSubnav = qs("#main-subnav")
  const submenuToggles = qsa("[data-submenu-toggle]")

  menuToggles.forEach((menuToggle) => {
    menuToggle.addEventListener("click", (e) => {
      e.preventDefault()
      if (body.classList.contains("menu-expanded")) {
        collapseAll()
      } else {
        openNav()
      }
    })
  })

  submenuToggles.forEach((submenuToggle) => {
    submenuToggle.addEventListener("click", (e) => {
      e.preventDefault()
      if (body.classList.contains("submenu-expanded")) {
        closeSubnav()
      } else {
        openSubnav()
      }
    })
  })

  body.addEventListener("keydown", (e) => {
    if (
      e.key === "Escape" &&
      (body.classList.contains("menu-expanded") ||
        body.classList.contains("submenu-expanded"))
    ) {
      collapseAll()
    }
  })

  const openNav = () => {
    mainNav.classList.remove("hidden")
    setTimeout(() => {
      body.classList.add("menu-expanded")
      mainNav.setAttribute("aria-expanded", true)
    }, 60)
  }

  const openSubnav = () => {
    mainSubnav.classList.remove("hidden")
    setTimeout(() => {
      body.classList.add("submenu-expanded")
      mainSubnav.setAttribute("aria-expanded", true)
    }, 60)
  }

  const closeNav = () => {
    body.classList.remove("menu-expanded")
    mainNav.setAttribute("aria-expanded", false)
    setTimeout(() => {
      mainNav.classList.add("hidden")
    }, 300)
  }
  const closeSubnav = () => {
    body.classList.remove("submenu-expanded")
    mainSubnav.setAttribute("aria-expanded", false)
    setTimeout(() => {
      mainSubnav.classList.add("hidden")
    }, 300)
  }

  const collapseAll = () => {
    closeNav()
    closeSubnav()
  }
}
