import { render } from "preact"
import { useEffect, useState } from "preact/hooks"
import { qs } from "../utils/dom"
import { setFilters } from "../utils/hooks"

export const initArticleList = () => {
  const list = qs("#articles-app")
  const listSrc = qs("#articles-app-list-data")
  const categoriesSrc = qs("#articles-app-categories-data")
  const selectedCategoriesSrc = qs("#articles-app-selected-categories-data")

  if (list && listSrc && categoriesSrc) {
    if (list.dataset.filtering)
      render(
        <ArticleApp
          articles={JSON.parse(listSrc.textContent)}
          categories={JSON.parse(categoriesSrc.textContent)}
          selected_categories={JSON.parse(selectedCategoriesSrc.textContent)}
        />,
        list,
      )
    else
      render(
        <SimilarArticlesApp
          articles={JSON.parse(listSrc.textContent)}
          categories={JSON.parse(categoriesSrc.textContent)}
        />,
        list,
      )
  }
}

const ArticleApp = ({ articles, categories, selected_categories }) => {
  const [activeFilters, setActiveFilters] = useState(selected_categories)

  const filteredArticles = articles.filter(
    (article) =>
      !activeFilters ||
      activeFilters.every((f) => article.categories.includes(f)),
  )

  useEffect(() => {
    const location =
      activeFilters.length > 0 ? `/lp/${activeFilters.sort().join("&")}/` : "/"
    history.pushState({}, null, location)
  }, [activeFilters])

  return (
    <>
      <div class="filter-buttons__wrapper">
        <div class="filter-buttons">
          {categories
            .filter((c) => !c.is_topic_of_the_year)
            .map((c, i) => (
              <FilterButton
                key={i}
                {...c}
                isActive={activeFilters.includes(c.slug)}
                onClick={() => {
                  setFilters(activeFilters, setActiveFilters, c.slug)
                }}
              />
            ))}
        </div>
        <div class="filter-buttons filter-buttons--topic-of-the-year">
          {categories
            .filter((c) => c.is_topic_of_the_year)
            .map((c, i) => (
              <FilterButton
                key={i}
                {...c}
                isActive={activeFilters.includes(c.slug)}
                onClick={() => {
                  setFilters(activeFilters, setActiveFilters, c.slug)
                }}
              />
            ))}
        </div>
      </div>
      <div class="auto-grid article-list">
        {filteredArticles.length === 0 ? (
          <div class="message">
            Keine Einträge gefunden.{" "}
            {activeFilters.length > 0 ? (
              <button
                class="button button--inline"
                onClick={() => {
                  setActiveFilters([])
                }}
              >
                Filter zurücksetzen
              </button>
            ) : undefined}
          </div>
        ) : (
          <>
            {filteredArticles.map((a, i) => (
              <ArticleCard
                key={i}
                {...a}
                activeFilters={activeFilters}
                categoryData={categories}
              />
            ))}
          </>
        )}
      </div>
    </>
  )
}

const SimilarArticlesApp = ({ articles, categories }) => {
  return (
    <div class="auto-grid article-list article-list--similar-articles">
      {articles.map((a, i) => (
        <ArticleCard key={i} {...a} categoryData={categories} />
      ))}
    </div>
  )
}

const FilterButton = (data) => {
  return (
    <button
      type="button"
      class={`button button--filter accent-${data.color} ${
        data.isActive ? "active" : ""
      }`}
      onClick={data.onClick}
    >
      {data.title}
    </button>
  )
}

const ArticleCard = (data) => {
  const primaryCategory = data.categoryData.find(
    (c) => c.slug === data.categories[0],
  )

  return (
    <a class="article-card" href={data.url}>
      {data.lead_image && (
        <div class="article-card__image">
          <img src={data.lead_image} alt={data.lead_image_alt} />
        </div>
      )}
      {data.activeFilters?.length > 0 && (
        <div class="article-card__active-filters">
          {data.categories
            .filter((c) => data.activeFilters.includes(c))
            .map((c, i) => (
              <Tag
                key={i}
                category={data.categoryData.find((d) => d.slug === c)}
              />
            ))}
        </div>
      )}
      <div class="article-card__text">
        <h2 class={`decorated accent-${primaryCategory.color}`}>
          <span>{data.title}</span>
        </h2>
        {data.teaser_text && <p>{data.teaser_text}</p>}
      </div>
      <div class="article-card__more">
        <div class="more-link">
          <span>weiterlesen</span>
          <svg class="icon">
            <use xlinkHref="#icon-arrow" />
          </svg>
        </div>
      </div>
    </a>
  )
}

const Tag = ({ category }) => {
  return (
    <div class={`category-tag accent-${category.color}`}>{category.title}</div>
  )
}
