import { qs } from "../utils/dom"

export const initHeroDown = () => {
  const heroDownEl = qs(".hero__down")

  if (heroDownEl) {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (!entry.isIntersecting) {
            entry.target.classList.add("fade")
          } else {
            entry.target.classList.remove("fade")
          }
        })
      },
      { threshold: 0.75 },
    )
    observer.observe(heroDownEl)
  }
}
